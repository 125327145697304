/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'main' %> to the appropriate
// layout file, like app/views/layouts/main.html.erb

import "@stimulus/polyfills"
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const commonContext = require.context("controllers/common", true, /.js$/)
application.load(definitionsFromContext(commonContext))

const context = require.context("controllers/main", true, /.js$/)
application.load(definitionsFromContext(context))
