import { Controller } from "stimulus"

export default class extends Controller {
  oauthPopup() {
    let button = event.target
    let url = button.getAttribute("href")
    let width = button.getAttribute('data-width')
    let height = button.getAttribute('data-height')

    let left = screen.width / 2 - (width / 2)
    let top = screen.height / 2 - (height / 2)
    window.open(url, 'authPopup', 'menubar=no,toolbar=no,status=no,width=' + width + ',height=' + height + ',toolbar=no,left=' + left + ',top=' + top)

    event.preventDefault()
  }
}
